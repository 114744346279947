import {FormFieldBase, FormFieldBaseOptions} from './form-field-base';

export interface FormFieldInputOptions<T = any> extends FormFieldBaseOptions<T> {
    type?: string;
    style?: string;
    limit?: number;
}

export class FormFieldInput<T = any> extends FormFieldBase<T> {
    controlType = 'input';
    type: string;
    style: string;

    constructor(options: FormFieldInputOptions<T> = {}) {
        super(options);
        this.controlType = options.controlType || this.controlType || 'input';
        this.type = options.type || '';
        this.style = options.style || '';
        this.limit = options.limit || null;
    }
}
