import {FormPropertyBase, FormPropertyBaseOptions} from './form-property-base';
import {CompareWithFn} from '@ng-select/ng-select/lib/ng-select.component';

export interface FormFieldBaseOptions<T = any, K extends keyof T & string = keyof T & string> extends FormPropertyBaseOptions<T> {
    value?: T[K];
    controlType?: string;
    multi?: boolean;
    limit?: number;
    searchTermMinLength?: number;
}

export class FormFieldBase<T = any, K extends keyof T & string = keyof T & string> extends FormPropertyBase<T> {
    value: T[K];
    controlType: string;
    multi?: boolean;
    limit?: number;
    searchTermMinLength?: number;

    type?: any;
    rows?: any;
    labelProp?: any;
    sameAsProp?: string;
    options?: any;
    asyncOptionsAPI?: any;
    addItemCallback?: any;
    addItemText?: any;
    valueProp?: any;
    needsTimezone?: any;
    virtualScroll?: boolean;
    compareWith?: CompareWithFn;
    hasTime?: boolean;
    searchFn?: (term: string, item: any) => boolean;

    constructor(options: FormFieldBaseOptions<T> = {}) {
        super(options);
        this.value = options.value as T[K];
        this.controlType = options.controlType || '';
        this.limit = options.limit || null;
        this.searchTermMinLength = options.searchTermMinLength || null;
    }
}
